<template>
  <div>

    <!-- Inicio url consulta -->
    <v-dialog v-model="urlShopee" class="text-center" persistent width="700">
      <v-card>
        <v-card-text style="padding-top: 17px">
          {{ this.strUrlShopee }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="#018656" text @click="urlOpen()">
            ABRIR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warnning" text @click="urlShopee = false">
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim url consulta -->

    <!-- Início filtro -->
    <v-dialog v-model="modal.filtro" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-app-bar-nav-icon @click="modal.filtro = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
          <v-icon class="ml-2"> fas fa-building </v-icon>
          <v-toolbar-title class="pl-4"> Filtrar empresas </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="limparFiltros">
            <v-icon>fas fa-broom</v-icon>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon @click="carregarEmpresas(), (modal.filtro = false)">
            <v-icon>fas fa-search</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-form>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field dense outlined prepend-icon="fas fa-fingerprint" label="Código" type="number"
                    autocomplete="off" v-model="filtros.codigo" clearable></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field dense outlined prepend-icon="fas fa-passport" label="CNPJ" type="text" autocomplete="off"
                    v-model="filtros.cnpj" clearable></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field dense outlined prepend-icon="far fa-id-card" label="Razão social" type="text"
                    autocomplete="off" v-model="filtros.razao_social" clearable></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field dense outlined prepend-icon="far fa-id-card" label="Nome fantasia" type="text"
                    autocomplete="off" v-model="filtros.nome_fantasia" clearable></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim filtro -->

    <!-- Inicio Status Pedidos -->
    <v-dialog v-model="statusPedidos" class="text-center" persistent width="900">
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-toolbar-title class="pl-4">
            {{ getTitleStatus }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-app-bar-nav-icon @click="statusPedidos = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="5">
              <v-data-table
                dense
                v-model="detalhes.appTray_status_pedidos"
                :headers="statuspedidostray.headers"
                :items="detalhes.appTray_status_pedidos"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>
            <v-col cols="12" md="7">
              <v-data-table
                dense
                v-model="detalhes.appTray_status_pedidos"
                :headers="statuspedidostray.headers"
                :items="this.statuspedidostray.conteudo"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                :loading="statuspedidostray.carregando"
                show-select
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="#018656" @click="btnSave(), statusPedidos = false">
            Confirmar Seleção
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- Fim Status Pedidos -->

    <!-- Inicio Status Pedidos Wake-->
    <v-dialog v-model="framePedidosWake" class="text-center" persistent width="900">
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-toolbar-title class="pl-4">
            {{ getTitleStatusWake }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-app-bar-nav-icon @click="framePedidosWake = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="5">
              <v-data-table
                dense
                v-model="detalhes.appstatuspedidosWake"
                :headers="statuspedidosWake.headers"
                :items="detalhes.appstatuspedidosWake"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>
            <v-col cols="12" md="7">
              <v-data-table
                dense
                v-model="detalhes.appstatuspedidosWake"
                :headers="statuspedidosWake.headers"
                :items="this.statuspedidosWake.conteudo"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                :loading="statuspedidosWake.carregando"
                show-select
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>

          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="#018656" @click="btnSave(), framePedidosWake = false">
            Confirmar Seleção
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- Fim Status Pedidos Wake-->

    <!-- Inicio Registrar Token -->
    <v-dialog v-model="frameRegistrarToken" class="text-center" persistent width="700">
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-toolbar-title class="pl-4">
            Registrar Token {{  }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-app-bar-nav-icon @click="frameRegistrarToken = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                dense
                v-model="canaisToken.codigo"
                :headers="canaisToken.headers"
                :items="canaisToken.conteudo"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                :loading="canaisToken.carregando"
                single-select
                show-select
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn dark color="#018656" @click="saveDetails(detalhes), frameRegistrarToken = false"> -->
          <v-btn dark color="#018656" @click="saveRegistrarToken()">
            Gravar Registro
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- Fim Registrar Token -->

    <!-- Início detalhes -->
    <v-dialog v-model="modal.detalhes" fullscreen hide-overlay transition="dialog-bottom-transition"
      style="margin-bottom:64px; overflow-y:scroll">
      <v-card>
        <v-toolbar color="#018656" dark style="position:fixed" width="100%">
          <v-icon class="ml-2"> fas fa-boxes </v-icon>
          <v-toolbar-title class="pl-4">
            Detalhes da empresa ({{ empresaAtual }})
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <template v-if="saveData">
            <v-btn depressed color="#018656" class="mt-4 btnSalvar" @click="saveDetails(detalhes)">
              Salvar
            </v-btn>
          </template>

          <v-app-bar-nav-icon @click="modal.detalhes = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>

        <v-card-text style="padding:64px 24px 20px;">
          <v-col class="pt-6">
            <v-form>
              <v-card outlined>
                <v-card-text>

                  <v-row>
                    <v-col cols="12" md="1">
                      <v-text-field dense outlined label="Código" type="text" autocomplete="off"
                        v-model="this.detalhes.codigo" :disabled="true" clearable></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field dense outlined label="CNPJ" type="text" autocomplete="off"
                        v-model="this.detalhes.cnpj" :disabled="true" clearable></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field dense outlined label="Razão social" type="text" autocomplete="off"
                        v-model="this.detalhes.razao_social" :disabled="true" clearable></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field dense outlined label="Nome fantasia" type="text" autocomplete="off"
                        v-model="this.detalhes.nome_fantasia" :disabled="true" clearable></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field dense outlined label="Telefone" type="text" autocomplete="off"
                        v-model="this.detalhes.telefone" :disabled="true" clearable></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-file-input accept="image/png, image/jpeg, image/bmp, image/svg" v-model="this.detalhes.image"
                        :disabled="!!detalhes.image ? true : false" label="Logo" dense outlined @change="btnSave">
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field dense outlined v-model="this.detalhes.email" label="Email para resposta notificações"
                        type="text" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Correios -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="rastreioCorreios"
                        :label="`Rastreio Correios`"></v-checkbox>
                    </v-col>
                    <v-col v-if="rastreioCorreios" cols="12" md="2">
                      <v-text-field dense outlined v-model="detalhes.contratocorreios" label="Contrato"
                        :rules="[() => !!detalhes.contratocorreios || 'Campo obrigatório quando rastreamento habilitado.']"
                        type="text" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="rastreioCorreios" cols="12" md="2">
                      <v-text-field dense outlined v-model="detalhes.cartaocorreios" label="Cartao"
                        :rules="[() => !!detalhes.cartaocorreios || 'Campo obrigatório quando rastreamento habilitado.']"
                        type="text" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="rastreioCorreios" cols="12" md="4">
                      <v-text-field dense outlined v-model="detalhes.emailcorreios" label="Email"
                        :rules="[() => !!detalhes.emailcorreios || 'Campo obrigatório quando rastreamento habilitado.']"
                        type="text" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="rastreioCorreios" cols="12" md="3">
                      <v-text-field dense outlined v-model="detalhes.senhacorreios"
                        :append-icon="show1 ? 'far fa-eye' : 'far fa-eye-slash'" :type="show1 ? 'text' : 'password'"
                        :rules="[() => !!detalhes.senhacorreios || 'Campo obrigatório quando rastreamento habilitado.']"
                        label="Senha" autocomplete="off" @click:append="show1 = !show1" @change="btnSave"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="rastreioCorreios" cols="12" md="1">
                    </v-col>
                    <v-col v-if="rastreioCorreios" cols="12" md="6">
                      <v-text-field dense outlined v-model="detalhes.tokencorreios" label="Token Autorização"
                        :rules="[() => !!detalhes.tokencorreios || 'Campo obrigatório quando rastreamento habilitado.']"
                        type="text" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Jad -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="rastreioJad" :label="`Rastreio Jad`" color="#018656"></v-checkbox>
                    </v-col>
                    <v-col v-if="rastreioJad" cols="12" md="6">
                      <v-text-field dense outlined prepend-icon="far fa-id-card" v-model="detalhes.tokenjad"
                        label="Token"
                        :rules="[() => !!detalhes.tokenjad || 'Campo obrigatório quando habilitado.']"
                        autocomplete="off" clearable @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-radio-group label="Rastrear por:-" v-model="detalhes.rastreioJad_tipo" row>
                        <v-radio label="Shipment Id" :value="0" color="#018656"></v-radio>
                        <v-radio label="Danfe" :value="1" color="#018656"></v-radio>
                        <v-radio label="Nota Fiscal" :value="2" color="#018656"></v-radio>
                        <v-radio label="Cte" :value="3" color="#018656"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- AlephCrm -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="rastreioAlephcrm" label="AlephCRM"></v-checkbox>
                    </v-col>
                    <v-col v-if="rastreioAlephcrm" cols="12" md="2">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.productAlephcrm"
                        label="Baixar Produtos"></v-checkbox>
                    </v-col>
                    <v-col v-if="rastreioAlephcrm" cols="12" md="4">
                      <v-text-field dense outlined prepend-icon="far fa-id-card" v-model="detalhes.accountid"
                        label="Id"
                        :rules="[() => !!detalhes.accountid || 'Campo obrigatório quando habilitado.']"
                        type="text" autocomplete="off" clearable @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="rastreioAlephcrm" cols="12" md="4">
                      <v-text-field dense outlined prepend-icon="far fa-id-card" v-model="detalhes.apikey"
                        label="Key"
                        :rules="[() => !!detalhes.apikey || 'Campo obrigatório quando habilitado.']"
                        type="text" autocomplete="off" clearable @change="btnSave"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- start Proceda -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.ftpProceda" label="FTP Proceda"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.ftpProceda" cols="12" md="3">
                      <v-text-field dense outlined v-model="detalhes.apeliFtpProceda" label="Apelido pasta FTP"
                        :rules="[() => !!detalhes.apeliFtpProceda || 'Campo obrigatório quando habilitado.']" type="text"
                        autocomplete="off" clearable @change="btnSave"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- end Proceda -->

              <!-- token sisfrete -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="rastreioTokenSisfrete"
                        :label="`Sisfrete Token`"></v-checkbox>
                    </v-col>
                    <v-col v-if="rastreioTokenSisfrete" cols="12" md="5">
                      <v-text-field dense outlined prepend-icon="far fa-id-card" append-icon="fas fa-times-circle"
                        v-model="this.detalhes.rastreioTokenSisfrete" label="Sisfrete Token" autocomplete="off" readonly
                        @click:append="confirmDeleteToken()" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="rastreioTokenSisfrete" cols="12" md="2">
                      <v-btn @change="btnSave" @click="generateToken()" color="#018656" dark>
                        Gerar Token
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>

              <!-- Bling V3 Token -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.appSisfreteBlingV3"
                        :label="`Bling Token (V3)`"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteBlingV3" cols="12" md="5">
                      <v-text-field dense outlined prepend-icon="far fa-id-card" append-icon="fas fa-times-circle"
                        v-model="detalhes.appSisfreteBlingV3" label="Sisfrete Token" autocomplete="off" readonly
                        @click:append="confirmDeleteTokenBling()" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteBlingV3" cols="12" md="2">
                      <v-btn @change="btnSave" @click="generateTokenBlingV3()" color="#018656" dark>
                        Gerar Token
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>

              <!-- FastCommerce -->
              <v-card outlined>
                <v-card-text>

                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.pedidosFastCommerce"
                        :label="`Fast Commerce`"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.pedidosFastCommerce" cols="12" md="2">
                      <v-text-field dense outlined v-model="detalhes.FastCommerce.StoreId" label="Id da Loja"
                        :rules="[() => !!detalhes.FastCommerce.StoreId || 'Campo obrigatório!!!']" type="text"
                        autocomplete="off" clearable @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.pedidosFastCommerce" cols="12" md="4">
                      <v-text-field dense outlined v-model="detalhes.FastCommerce.StoreName" label="Nome da Loja"
                        :rules="[() => !!detalhes.FastCommerce.StoreName || 'Campo obrigatório!!!']" type="text"
                        autocomplete="off" clearable @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.pedidosFastCommerce" cols="12" md="3">
                      <v-text-field dense outlined v-model="detalhes.FastCommerce.Username" label="Usuário"
                        :rules="[() => !!detalhes.FastCommerce.Username || 'Campo obrigatório!!!']" type="text"
                        autocomplete="off" clearable @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.pedidosFastCommerce" cols="12" md="2">
                      <v-text-field dense outlined v-model="detalhes.FastCommerce.Password"
                        :rules="[() => !!detalhes.FastCommerce.Password || 'Campo obrigatório!!!']" label="Senha Usuário"
                        :append-icon="show3 ? 'far fa-eye' : 'far fa-eye-slash'" :type="show3 ? 'text' : 'password'"
                        autocomplete="off" @click:append="show3 = !show3" @change="btnSave"></v-text-field>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>

              <!-- Integracao -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.integracao" :label="`Integração`"
                        @change="btnSave"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.integracao" cols="12" md="5">
                      <v-select dense outlined label="Integradoras" v-model="detalhes.integracao"
                        :items="this.integradores" item-text="nome" item-value="codigo" clearable
                        @change="btnSave"></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Rastreio Bling -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.rastreioBling" :label="`Rastreio Bling`"
                        @change="btnSave"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- ML App Sisfrete -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.appSisfreteML" :label="`ML App Sisfrete`"
                        @change="btnSave"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteML" cols="12" md="5">
                      <v-text-field dense outlined append-icon="fas fa-times-circle" v-model="detalhes.authorizationML"
                        label="Autorização ML APP Sisfrete" autocomplete="off" readonly @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteML" cols="12" md="2">
                      <v-btn @click="authorizationML()" :color="getColor(this.renovarAutho, '#018656')" dark>
                        {{ !detalhes.authorizationML ? "Autorizar" : "Renovar" }}
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>

              <!-- Nuvemshop App Sisfrete -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.appSisfreteNS"
                        :label="`Nuvemshop App Sisfrete`"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteNS" cols="12" md="4">
                      <v-text-field dense outlined append-icon="fas fa-times-circle" v-model="detalhes.urlNuvemShop"
                        label="Url da Loja em Nuvemshop" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteNS && detalhes.userIdNS" cols="12" md="1">
                      <v-text-field dense outlined v-model="detalhes.userIdNS" label="Id" autocomplete="off"
                        readonly></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteNS" cols="12" md="2">
                      <v-text-field dense outlined v-model="detalhes.tokenSisfreteNS" label="Token Utilizado"
                        autocomplete="off" readonly></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteNS && detalhes.userIdNS" cols="12" md="2">
                      <v-select dense outlined :items="statusNuvem" label="Método de Envio"
                        v-model="detalhes.enviosSisfreteNS" hide-details readonly
                        :color="!detalhes.enviosSisfreteNS ? 'red' : ''"
                        :bg-color="!detalhes.enviosSisfreteNS ? 'red' : ''"></v-select>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteNS && detalhes.userIdNS" cols="12" md="2">
                      <v-select dense outlined :items="statusNuvem" label="Opções de Envio"
                        v-model="detalhes.opcaoSisfreteNS" hide-details readonly
                        :color="!detalhes.enviosSisfreteNS ? 'red' : ''"
                        :bg-color="!detalhes.enviosSisfreteNS ? 'red' : ''"></v-select>
                    </v-col>
                  <!-- </v-row>
                  <v-row>
                    <v-col v-if="detalhes.appSisfreteNS" cols="12" md="1">
                    </v-col> -->
                    <v-col v-if="detalhes.appSisfreteNS && detalhes.userIdNS" cols="12" md="2">
                      <v-select dense outlined :items="modoNuvem" label="Modo Cotação" v-model="detalhes.modoSisfreteNS"
                        :color="!detalhes.modoSisfreteNS ? 'red' : ''" @change="updateSisfreteNS"></v-select>
                    </v-col>

                    <v-col v-if="detalhes.appSisfreteNS && detalhes.urlNuvemShop" cols="12" md="1">
                      <v-btn @click="authorizationNS(detalhes)" color="#018656" dark>
                        {{ this.urlNuvemShop == '' ? "Instalar" : "Remover" }}
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>

              <!-- Tray App Sisfrete -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.appSisfreteTray"
                        :label="`Tray App Sisfrete`"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteTray" cols="12" md="4">
                      <v-text-field dense outlined v-model="detalhes.appTray_api_address" readonly
                        label="Url da Loja em Tay" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteTray" cols="12" md="4">
                      <v-text-field dense outlined v-model="detalhes.appTray_code" label="Code" autocomplete="off"
                        readonly></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteTray" cols="12" md="2">
                      <v-text-field dense outlined v-model="detalhes.appTray_token" label="Token Utilizado"
                        autocomplete="off" readonly></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="detalhes.appSisfreteTray">
                    <v-col cols="12" md="1">
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-btn depressed color="#018656" dark @click="fnStatusPedidos">
                        {{ detalhes.appTray_status_pedidos.length > 0 ? "Status dos Pedidos" : "Selecionar os Status dos Pedidos" }}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Wake -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.appSisfreteWake"
                        :label="`Wake`" @change="btnSave">
                      </v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteWake" cols="12" md="5">
                      <v-text-field dense outlined v-model="detalhes.apptokenWake"
                        label="Token Wake" autocomplete="off" @change="btnSave">
                      </v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteWake" cols="12" md="2">
                      <v-select dense outlined :items="modoNuvem" v-model="detalhes.modoSisfreteWake"
                        label="Modo Integração" :color="!detalhes.modoSisfreteWake ? 'red' : ''" @change="btnSave">
                      </v-select>
                    </v-col>

                    <v-col v-if="detalhes.appSisfreteWake" cols="12" md="4">
                      <v-btn depressed color="#018656" dark @click="fnStatusPedidosWake">
                        {{ detalhes.appstatuspedidosWake.length > 0 ? "Status dos Pedidos" : "Selecionar os Status dos Pedidos" }}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Anymarket App Sisfrete -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.appSisfreteAny"
                        :label="`Anymarket App Sisfrete`" @change="btnSave"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteAny" cols="12" md="5">
                      <v-text-field dense outlined v-model="detalhes.apptokenAny"
                        label="Token Anymarket" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteAny" cols="12" md="3">
                      <v-text-field dense outlined v-model="detalhes.appoiAny"
                        label="Oi Anymarket" autocomplete="off" @change="btnSave"></v-text-field>
                    </v-col>

                    <v-col v-if="detalhes.appSisfreteAny" cols="12" md="2">
                      <v-select dense outlined :items="modoNuvem" label="Modo Integração" v-model="detalhes.modoSisfreteAny"
                        :color="!detalhes.modoSisfreteAny ? 'red' : ''" @change="btnSave"></v-select>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Shopee -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="1">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.appSisfreteShopee"
                        :label="`Shopee App Sisfrete`" @change="btnSave">
                      </v-checkbox>
                    </v-col>

                    <v-col v-if="detalhes.appSisfreteShopee && !detalhes.appShopeeSellerCode" cols="12" md="2">
                      <v-btn depressed color="#018656" dark @click="urlConsulta(1)">
                        Autorizar App Seller
                      </v-btn>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteShopee && appShopeeShopId" cols="12" md="2">
                      <v-text-field dense outlined v-model="appShopeeShopId" readonly
                        label="Shop_Id" autocomplete="off" @change="btnSave">
                      </v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteShopee && detalhes.appShopeeSellerCode" cols="12" md="4">
                      <v-text-field dense outlined v-model="detalhes.appShopeeSellerCode" readonly
                        label="Seller Code" autocomplete="off" @change="btnSave">
                      </v-text-field>
                    </v-col>
                    <!-- <v-col v-if="detalhes.appSisfreteShopee && detalhes.appShopeeSellerShopId" cols="12" md="3">
                      <v-text-field dense outlined v-model="detalhes.appShopeeSellerShopId" readonly
                        label="Seller Shop_Id" autocomplete="off" @change="btnSave">
                      </v-text-field>
                    </v-col> -->
                    <!-- <v-col v-if="detalhes.appSisfreteShopee && detalhes.appShopeeSellerAccountId" cols="12" md="3">
                      <v-text-field dense outlined v-model="detalhes.appShopeeSellerAccountId" readonly
                        label="Seller Account_Id" autocomplete="off" @change="btnSave">
                      </v-text-field>
                    </v-col> -->

                    <v-col v-if="detalhes.appSisfreteShopee && !detalhes.appShopeeErpCode" cols="12" md="2">
                      <v-btn depressed color="#018656" dark @click="urlConsulta(2)">
                        Autorizar App Erp
                      </v-btn>
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteShopee && detalhes.appShopeeErpCode" cols="12" md="4">
                      <v-text-field dense outlined v-model="detalhes.appShopeeErpCode" readonly
                        label="Erp Code" autocomplete="off" @change="btnSave">
                      </v-text-field>
                    </v-col>
                    <!-- <v-col v-if="detalhes.appSisfreteShopee && detalhes.appShopeeErpShopId" cols="12" md="3">
                      <v-text-field dense outlined v-model="detalhes.appShopeeErpShopId" readonly
                        label="Erp Shop_Id" autocomplete="off" @change="btnSave">
                      </v-text-field>
                    </v-col> -->
                    <!-- <v-col v-if="detalhes.appSisfreteShopee && detalhes.appShopeeErpAccountId" cols="12" md="3">
                      <v-text-field dense outlined v-model="detalhes.appShopeeErpAccountId" readonly
                        label="Erp Account_Id" autocomplete="off" @change="btnSave">
                      </v-text-field>
                    </v-col> -->
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="1">
                    </v-col>
                    <v-col v-if="detalhes.appSisfreteShopee && appShopeeShopId" cols="12" md="4">
                      <v-text-field dense outlined v-model="detalhes.appShopeeToken" label="Token Utilizado"
                        autocomplete="off" readonly>
                      </v-text-field>
                    </v-col>

                    <v-col v-if="detalhes.appSisfreteShopee && appShopeeShopId" cols="12" md="2">
                      <v-btn @click="fnRegistrarToken(21)" color="#018656" dark>
                        {{ !!detalhes.appShopeeToken ? "Alterar Token Registrado" : "Registrar Token" }}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>

              <!-- api-key integrador logistico -->
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-checkbox class="shrink mr-2 mt-0" v-model="detalhes.pedidosIntLog"
                        :label="`Integrador Logistico`"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.pedidosIntLog" cols="12" md="5">
                      <v-text-field dense outlined prepend-icon="far fa-id-card" append-icon="fas fa-times-circle"
                        v-model="this.detalhes.apikeyIntLog" label="Api Key Integrador Logistico" autocomplete="off" readonly
                        @click:append="DeleteApiKey()" @change="btnSave"></v-text-field>
                    </v-col>
                    <v-col v-if="detalhes.pedidosIntLog" cols="12" md="2">
                      <v-btn @change="btnSave" @click="generateApiKey()" color="#018656" dark>
                        Gerar Api Key
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>

              <!-- Notifications -->
              <v-card>
                <v-card-text>

                  <v-row>
                    <v-col cols="12" md="2">
                      <v-checkbox :riple="false" class="shrink mr-2 mt-0" v-model="detalhes.notifications"
                        :label="`Notificações`" @click="loadNotify()" @change="btnSave"></v-checkbox>
                    </v-col>
                    <v-col v-if="detalhes.notifications" cols="12" md="4">
                      <v-data-table dense v-model="notifications.selected" :headers="notifications.headers"
                        :items="this.notifications.desserts" hide-default-footer class="elevation-1 pt-4" show-select
                        @click:row="handleClick" item-key="channel" fixed-header @change="btnSave">
                        <template v-slot:item.timeline="{ item }">
                          <template v-for="(title, index) in item.timeline.items">
                            <v-checkbox v-model="item.timeline.selected" :label="title.label" :value="title.value"
                              @change="btnSave"></v-checkbox>
                          </template>
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col v-if="detalhes.notifications" cols="12" md="5">
                      <v-card-title style="justify-content: center;">Mensagens:</v-card-title>
                      <v-card-item style="display: flex;">
                        <v-textarea :disabled="true" outlined auto-grow label="Produto enviado"
                          placeholder="Mensagem quando o produto for enviado" v-model="this.detalhes.message_notify_start"
                          @change="btnSave">
                        </v-textarea>

                        <v-textarea style="position: relative; left: 30px;" :disabled="true" outlined auto-grow
                          label="Produto entregue" placeholder="Mensagem quando o produto for entregue"
                          v-model="this.detalhes.message_notify_end" @change="btnSave">
                        </v-textarea>
                      </v-card-item>
                      <v-card-item>
                        <v-textarea :disabled="true" outlined auto-grow label="Mensagem para notificação SMS"
                          v-model="this.detalhes.message_notify_sms" @change="btnSave">
                        </v-textarea>
                      </v-card-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-text>

                  <v-btn v-if="saveData" depressed color="#018656" class="mt-4 btnSalvar" @click="saveDetails(detalhes)">
                    Salvar
                  </v-btn>
                </v-card-text>
              </v-card>

            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Detalhes -->
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-building </v-icon>
        <v-toolbar-title class="pl-4"> Empresas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table dense :headers="grid.empresas.cabecalho" :items="grid.empresas.items"
              :items-per-page="grid.empresas.paginacao" hide-default-footer class="elevation-1 pt-4"
              :loading="grid.empresas.carregando">
              <template #item.renovarAutho="{ item }">
                <v-tooltip v-if="item.renovarAutho" bottom>
                  <template v-slot:activator="{ on }">
                    <v-app-bar-nav-icon v-on="on">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <td>
                          <v-icon size="16" :color="getColor(item.renovarAutho)">fa-solid fa-circle</v-icon>
                        </td>
                      </div>
                    </v-app-bar-nav-icon>
                  </template>
                  <span>Ação Necessária. Renovar Autorização</span>
                </v-tooltip>
              </template>

              <template v-slot:item.controls="{ item }">
                <v-app-bar-nav-icon @click="carregarDetalhes(item)">
                  <v-icon>fas fa-search</v-icon>
                </v-app-bar-nav-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl, baseApiUrlPersys, MLAppSisfrete } from "@/global";
import axios from "axios";

export default {
  name: "Empresas",
  data: () => ({
    codigo_empresa: '',
    grid: {
      empresas: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "CNPJ", value: "cnpj", sortable: false },
          { text: "Razão social", value: "razao_social", sortable: false },
          { text: "Nome fantasia", value: "nome_fantasia", sortable: false },
          { text: "Logo", value: "imagem", sortable: false },
          { text: "", value: "renovarAutho", sortable: false },
          { text: "", value: "controls", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        porPagina: 10,
      },
    },
    notifications: {
      selected: [],
      headers: [
        { text: "Notificar", value: "channel", sortable: false },
        { text: "Como?", value: "type", sortable: false },
        { text: "Time Lime", value: "timeline", sortable: false },
      ],
      desserts: [],
    },
    statuspedidostray: {
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      conteudo: [],
      carregando: false,
    },
    statuspedidosWake: {
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      conteudo: [],
      carregando: false,
    },
    canaisToken: {
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Nome", value: "nome", sortable: false },
        { text: "Token", value: "token", sortable: false },
        { text: "Canal", value: "canal", sortable: false },
      ],
      codigo: [],
      conteudo: [],
      carregando: false,
    },
    timeline: {
      selected: [],
      items: [
        { label: "Postado", value: 1 },
        { label: "Em Trânsito", value: 2 },
        { label: "Saiu Entrega", value: 3 },
        { label: "Entregue", value: 4 },
        { label: "Alerta", value: 5 },
      ]
    },
    modal: {
      filtro: false,
      detalhes: false,
    },
    filtros: {
      codigo: "",
      cnpj: "",
      razao_social: "",
      nome_fantasia: "",
    },
    detalhes: {
      email: '',
      rastreioTokenSisfrete: "",
      pedidosFastCommerce: false,
      FastCommerce: {
        StoreId: '',
        StoreName: '',
        Username: '',
        Password: '',
      },
      rastreioCorreios: false,
      emailcorreios: '',
      senhacorreios: '',
      tokencorreios: '',
      appSisfreteBlingV3: false,
      rastreioJad: false,
      tokenjad: "",
      rastreioJad_tipo: false,
      rastreioAlephcrm: false,
      productAlephcrm: false,
      apikey: '',
      accountid: '',
      ftpProceda: false,
      apeliFtpProceda: '',
      appSisfreteML: false,
      authorizationML: '',
      notifications: false,
      notify: [],
      image: '',
      message_notify_start: '',
      message_notify_end: '',
      message_notify_sms: '',
      integracao: false,
      rastreioBling: false,
      appSisfreteNS: false,
      urlNuvemShop: '',
      userIdNS: '',
      tokenSisfreteNS: '',
      enviosSisfreteNS: false,
      opcaoSisfreteNS: false,
      modoSisfreteNS: false,
      appSisfreteTray: false,
      appTray_code: '',
      appTray_api_address: '',
      appTray_token: '',
      appTray_status_pedidos: [],
      appSisfreteAny: false,
      apptokenAny: '',
      appoiAny: '',
      modoSisfreteAny: false,
      appSisfreteWake: false,
      apptokenWake: '',
      modoSisfreteWake: false,
      appstatuspedidosWake: [],
      appSisfreteShopee: false,
      appShopeeSellerCode: '',
      appShopeeSellerShopId: '',
      appShopeeErpCode: '',
      appShopeeErpShopId: '',
      appShopeeToken: null,
      pedidosIntLog: false,
      apikeyIntLog: '',
    },
    urlNuvemShop: '',
    appShopeeShopId: '',
    renovarAutho: false,
    statusNuvem: [{ text: "Não importado", value: 0 }, { text: "Importado", value: 1 }],
    modoNuvem: [{ text: "Homologação", value: 0 }, { text: "Produção", value: 1 }],
    windowNew: {
      url: `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=${MLAppSisfrete}&redirect_uri=https://api.cliente.sandbox.sisfrete.com.br/api/v1/AppSisfreteML`,
      name: 'AuthorizationML',
      size: 'width=500,height=300',
      ret: '',
    },
    empresaAtual: "",
    rastreioTokenSisfrete: false,
    rastreioCorreios: false,
    rastreioJad: false,
    rastreioAlephcrm: false,
    integradores: [],
    saveData: false,
    show1: false,
    show2: false,
    show3: false,
    factory: "",
    statusPedidos: false,
    framePedidosWake: false,
    frameRegistrarToken: false,
    statusSelecionados: '0',
    urlShopee: false,
    strUrlShopee: '',
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.carregarEmpresas();
    this.carregarTokenSisfrete();
    this.carregarIntegradores();
  },
  methods: {
    handleClick(obj) {
      this.notifications.selected.forEach((element, key) => {
        if (element.channel === obj.channel) {
          this.notifications.selected.splice(key, 1);
          this.notifications.selected.push({ channel: obj.channel, type: obj.type == "Todos pedidos" ? 1 : 0, timeline: obj.timeline.selected });
        }
      });
    },
    limparFiltros() {
      this.detalhes = {};
    },
    getColor(type, cor) {
      let color = cor; // branco
      if (type == 1) {
        color = "#FF0000"; // vermelho
      }
      return color;
    },
    fechar() {
      this.$router.push("/");
    },
    async urlCop() {
      await navigator.clipboard.writeText(this.strUrlShopee);
      this.urlShopee = false;
    },
    async urlOpen() {
      window.open(this.strUrlShopee, '_blank', 'noreferrer');
      this.urlShopee = false;

      this.saveData = false;
      this.modal.detalhes = false;

    },
    async urlConsulta(typelink) {
      let url = `${baseApiUrl}/authshopee`;
      let link = typelink == 1 ? "Sel" : "Erp";
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&link=${link}`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.retorno) {
            this.strUrlShopee = `${response.data.dados}`;
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: `${error}`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => {
          this.urlShopee = true;
        });
    },
    async fnStatusPedidos() {
      this.statuspedidostray.conteudo = [];
      this.statusPedidos = true;
      this.statuspedidostray.carregando = true;
      await axios
        .get(`${baseApiUrl}/statusTray/${this.codigo_empresa}`)
        .then((res) => {
          if (res.data.retorno) {
            this.statuspedidostray.conteudo = res.data.statusTray;
          }
        })
        .finally(() => {
          this.statuspedidostray.carregando = false;
        });
    },
    async fnStatusPedidosWake() {
      this.statuspedidosWake.conteudo = [];
      this.framePedidosWake = true;
      this.statuspedidosWake.carregando = true;
      await axios
        .get(`${baseApiUrl}/statusWake/${this.codigo_empresa}`)
        .then((res) => {
          if (res.data.retorno) {
            this.statuspedidosWake.conteudo = res.data.statusWake;
          }
        })
        .finally(() => {
          this.statuspedidosWake.carregando = false;
        });
    },
    async fnRegistrarToken(codeChannel) {
      this.frameRegistrarToken = true;
      this.canaisToken.carregando = true;
      await axios
        .get(`${baseApiUrl}/statusChannel/${this.codigo_empresa}/${codeChannel}`)
        .then((res) => {
          if (res.data.retorno) {
            this.canaisToken.conteudo = res.data.dados;
            this.canaisToken.conteudo.forEach(element => {
              if (element.id == Number(this.detalhes.appShopeeToken.substr(0, 4))) {
                this.canaisToken.codigo = [element];
              }
            });
          }
        })
        .finally(() => {
          this.canaisToken.carregando = false;
        });
    },
    async vincularSeller(tokenId) {
      await axios
        .post(`${baseApiUrl}/linkChannel/${this.codigo_empresa}/${tokenId}`, { data: this.appShopeeShopId })
        .then((res) => {});
    },
    async saveRegistrarToken() {
      let idToken = 0;
      this.canaisToken.conteudo.forEach(element => {
        if (element.id == this.canaisToken.codigo[0].id) {
          this.detalhes.appShopeeToken = `${this.canaisToken.codigo[0].id} - ${this.canaisToken.codigo[0].nome}`
          idToken = `${this.canaisToken.codigo[0].id}`
        }
      });
      if (idToken) {
        this.vincularSeller(idToken);
        this.saveDetails(this.detalhes);
        this.frameRegistrarToken = false;
      }
    },
    async saveToken() {
      await axios
        .post(`${baseApiUrlPersys}/chaves`, {
          codigoEmpresa: this.codigo_empresa,
          tokenAPI: this.detalhes.rastreioTokenSisfrete,
        })
        .then((res) => {
          this.$swal({
            toast: true,
            position: 'top-right',
            icon: 'success',
            title: 'Sisfrete Token salvo com sucesso.',
            showConfirmButton: false,
            timer: 2500
          });

          if (res.data.tokenApi == "" || res.data.tokenApi == null)
            this.rastreioTokenSisfrete = false;
          else
            this.rastreioTokenSisfrete = true;
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },
    async carregarTokenSisfrete() {
      await axios
        .get(`${baseApiUrlPersys}/chaves/${this.codigo_empresa}`)
        .then((res) => {
          this.detalhes.rastreioTokenSisfrete = res.data.tokenApi;
          if (this.detalhes.rastreioTokenSisfrete == "" || this.detalhes.rastreioTokenSisfrete == null)
            this.rastreioTokenSisfrete = false;
          else
            this.rastreioTokenSisfrete = true;
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },
    carregarEmpresas() {
      this.grid.empresas.carregando = true;
      let url = `${baseApiUrl}/empresas?page=${this.grid.empresas.paginaAtual}`;
      if (
        this.filtros.codigo != "" ||
        this.filtros.cnpj != "" ||
        this.filtros.razao_social != "" ||
        this.filtros.nome_fantasia != ""
      ) {
        url += `&codigo=${this.filtros.codigo}`;
        url += `&cnpj=${this.filtros.cnpj}`;
        url += `&razao_social=${this.filtros.razao_social}`;
        url += `&nome_fantasia=${this.filtros.nome_fantasia}`;
      }
      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${this.codigo_empresa}`;

      axios
        .get(url)
        .then((res) => {
          this.grid.empresas.items = res.data.empresas.data;
          this.grid.empresas.totalRegistros = res.data.empresas.total;
        })
        .finally(() => (this.grid.empresas.carregando = false));
    },
    carregarDetalhes(item) {
      this.empresaAtual = `${item.codigo}-${item.razao_social}`;
      this.modal.detalhes = true;
      this.grid.empresas.carregando = true;
      let url = `${baseApiUrl}/empresas-detalhes?codigo=${item.codigo}&type=detalhes`;

      axios
        .get(url)
        .then((res) => {
          this.detalhes.codigo = res.data.empresas.data[0].codigo;
          this.detalhes.cnpj = res.data.empresas.data[0].cnpj;
          this.detalhes.razao_social = res.data.empresas.data[0].razao_social;
          this.detalhes.nome_fantasia = res.data.empresas.data[0].nome_fantasia;
          this.detalhes.telefone = res.data.empresas.data[0].telefone;
          this.detalhes.email = res.data.empresas.data[0].email;
          this.detalhes.contratocorreios = res.data.empresas.data[0].contratocorreios;
          this.detalhes.cartaocorreios = res.data.empresas.data[0].cartaocorreios;
          this.detalhes.rastreioCorreios = res.data.empresas.data[0].rastreioCorreios;
          this.detalhes.emailcorreios = res.data.empresas.data[0].emailcorreios == null ? "" : res.data.empresas.data[0].emailcorreios;
          this.detalhes.senhacorreios = res.data.empresas.data[0].senhacorreios == null ? "" : res.data.empresas.data[0].senhacorreios;
          this.detalhes.tokencorreios = res.data.empresas.data[0].tokencorreios == null ? "" : res.data.empresas.data[0].tokencorreios;
          this.detalhes.rastreioJad = res.data.empresas.data[0].rastreioJad;
          this.detalhes.tokenjad = res.data.empresas.data[0].tokenjad == null ? "" : res.data.empresas.data[0].tokenjad;
          this.detalhes.rastreioJad_tipo = res.data.empresas.data[0].rastreioJad_tipo;
          this.detalhes.rastreioAlephcrm = res.data.empresas.data[0].rastreioAlephcrm;
          this.detalhes.productAlephcrm = res.data.empresas.data[0].productAlephcrm;
          this.detalhes.pedidosFastCommerce = res.data.empresas.data[0].pedidosFastCommerce;
          this.detalhes.apikey = res.data.empresas.data[0].apikey == null ? "" : res.data.empresas.data[0].apikey;
          this.detalhes.accountid = res.data.empresas.data[0].accountid == null ? "" : res.data.empresas.data[0].accountid;
          this.detalhes.ftpProceda = res.data.empresas.data[0].ftpProceda;
          this.detalhes.apeliFtpProceda = res.data.empresas.data[0].apelidoFtp;
          this.detalhes.integracao = res.data.empresas.data[0].integracao;
          this.detalhes.rastreioBling = res.data.empresas.data[0].rastreioBling;
          this.detalhes.appSisfreteBlingV3 = res.data.empresas.data[0].appSisfreteBlingV3;
          this.rastreioCorreios = this.detalhes.rastreioCorreios;
          this.rastreioJad = this.detalhes.rastreioJad;
          this.rastreioAlephcrm = this.detalhes.rastreioAlephcrm;
          if (res.data.empresas.data[0].rastreioFastCommerce) {
            let fieldsFastCommerce = JSON.parse(res.data.empresas.data[0].rastreioFastCommerce);
            this.detalhes.FastCommerce.StoreId = fieldsFastCommerce.StoreId;
            this.detalhes.FastCommerce.StoreName = fieldsFastCommerce.StoreName;
            this.detalhes.FastCommerce.Username = fieldsFastCommerce.Username;
            this.detalhes.FastCommerce.Password = fieldsFastCommerce.Password;
          }
          this.detalhes.appSisfreteML = res.data.empresas.data[0].appSisfreteML;
          this.detalhes.authorizationML = res.data.empresas.data[0].authorizationML;
          this.detalhes.notifications = res.data.empresas.data[0].notifications;
          this.detalhes.appSisfreteNS = res.data.empresas.data[0].appSisfreteNS;
          this.detalhes.urlNuvemShop = res.data.empresas.data[0].urlNuvemShop;
          this.urlNuvemShop = res.data.empresas.data[0].urlNuvemShop == null ? "" : res.data.empresas.data[0].urlNuvemShop;
          this.detalhes.userIdNS = res.data.empresas.data[0].userIdNS;
          this.detalhes.tokenSisfreteNS = res.data.empresas.data[0].tokenSisfreteNS == null ? "" : res.data.empresas.data[0].tokenSisfreteNS;
          this.detalhes.enviosSisfreteNS = res.data.empresas.data[0].enviosSisfreteNS == null ? 0 : res.data.empresas.data[0].enviosSisfreteNS;
          this.detalhes.opcaoSisfreteNS = res.data.empresas.data[0].opcaoSisfreteNS == null ? 0 : res.data.empresas.data[0].opcaoSisfreteNS;
          this.detalhes.modoSisfreteNS = res.data.empresas.data[0].modoSisfreteNS;
          this.detalhes.appSisfreteTray = res.data.empresas.data[0].appSisfreteTray;
          this.detalhes.appTray_code = res.data.empresas.data[0].appTray_code;
          this.detalhes.appTray_api_address = res.data.empresas.data[0].appTray_api_address;
          this.detalhes.appTray_token = res.data.empresas.data[0].appTray_token;
          this.detalhes.appSisfreteAny = res.data.empresas.data[0].appSisfreteAny;
          this.detalhes.apptokenAny = res.data.empresas.data[0].apptokenAny;
          this.detalhes.appoiAny = res.data.empresas.data[0].appoiAny;
          this.detalhes.modoSisfreteAny = res.data.empresas.data[0].modoSisfreteAny;
          this.detalhes.appSisfreteWake = res.data.empresas.data[0].appSisfreteWake;
          this.detalhes.apptokenWake = res.data.empresas.data[0].apptokenWake;
          this.detalhes.modoSisfreteWake = res.data.empresas.data[0].modoSisfreteWake;
          if (res.data.empresas.data[0].notifications) {
            this.detalhes.notifications = true;
            this.loadNotify();
          }
          this.detalhes.image = res.data.empresas.data[0].image;
          this.detalhes.message_notify_start = res.data.empresas.data[0].message_notify_start;
          this.detalhes.message_notify_end = res.data.empresas.data[0].message_notify_end;
          this.detalhes.message_notify_sms = res.data.empresas.data[0].message_notify_sms;
          this.renovarAutho = res.data.empresas.data[0].renovarAutho == null ? 0 : res.data.empresas.data[0].renovarAutho;

          this.detalhes.appTray_status_pedidos = [];
          let appTray_status_pedidos = res.data.empresas.data[0].appTray_status_pedidos == null ? [] : JSON.parse(res.data.empresas.data[0].appTray_status_pedidos);
          appTray_status_pedidos.forEach(element => {
            this.detalhes.appTray_status_pedidos.push({ id: element.id, status: element.status });
          });

          this.detalhes.appstatuspedidosWake = [];
          let appstatuspedidosWake = res.data.empresas.data[0].appstatuspedidosWake == null ? [] : JSON.parse(res.data.empresas.data[0].appstatuspedidosWake);
          appstatuspedidosWake.forEach(element => {
            this.detalhes.appstatuspedidosWake.push({ id: element.id, status: element.status });
          });

          this.detalhes.appSisfreteShopee = res.data.empresas.data[0].appSisfreteShopee;
          this.detalhes.appShopeeSellerCode = res.data.empresas.data[0].appShopeeSellerCode;
          this.detalhes.appShopeeSellerShopId = res.data.empresas.data[0].appShopeeSellerShopId;
          this.detalhes.appShopeeErpCode = res.data.empresas.data[0].appShopeeErpCode;
          this.detalhes.appShopeeErpShopId = res.data.empresas.data[0].appShopeeErpShopId;
          this.detalhes.appShopeeToken = res.data.empresas.data[0].appShopeeToken;
          this.appShopeeShopId = res.data.empresas.data[0].appShopeeSellerShopId ?? res.data.empresas.data[0].appShopeeErpShopId;

          this.detalhes.pedidosIntLog = res.data.empresas.data[0].pedidosIntLog;
          this.detalhes.apikeyIntLog = res.data.empresas.data[0].apikeyIntLog;

        })
        .catch((error) => {
          console.log( error )
          this.grid.empresas.carregando = false;
        })
        .finally(() => (this.grid.empresas.carregando = false)
        );
    },
    carregarIntegradores() {
      let url = `${baseApiUrl}/integradores`;
      axios.get(url)
        .then((res) => {
          this.integradores = res.data.dados;
        });
    },
    loadNotify() {
      if (this.detalhes.notifications) {
        let url = `${baseApiUrl}/list-notify?cod_empresa=${this.codigo_empresa}`;
        let channel = '';
        let timeline = { selected: [] }
        axios
          .get(url)
          .then((res) => {
            if (res.data.dados) {
              this.notifications.desserts = [];
              res.data.dados.forEach(element => {
                if (element.Canal) {
                  channel = element.Canal
                } else {
                  channel = element.nome
                }
                this.notifications.desserts.push({ channel: channel, type: element.Como, timeline: { selected: [], items: this.timeline.items } })
              });

              if (res.data.notify) {
                this.notifications.selected = [];
                res.data.notify.forEach(element => {
                  let type = 0;
                  let timeline = element.timeline == null ? [] : element.timeline;
                  this.notifications.desserts.forEach(eleSel => {
                    if (eleSel.channel === element.channel) {
                      eleSel.timeline.selected = timeline;
                      type = eleSel.Como == "Todos pedidos" ? 1 : 0
                    }
                  });
                  this.notifications.selected.push({ channel: element.channel, type: type, timeline: timeline });
                });
              }
            }
          })
          .finally();
      }
    },
    mudarPagina(pagina) {
      this.grid.empresas.paginaAtual = pagina;
      this.carregarEmpresas();
    },
    saveDetails(formUpdate) {
      console.log(this.notifications.desserts);
      this.$swal({
        title: 'Alerta',
        html: `Salvar as alterações ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Concluir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            let retSend = this.saveForm(formUpdate);
            if (retSend) {
              this.$swal({
                title: 'Sucesso!!!',
                text: 'Informações Alteradas com sucesso.',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            } else if (retSend == false) {
              this.$swal({
                title: 'Que pena!!!',
                text: `${response.data.mensagem}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            } else if (retSend == -1) {
              this.$swal({
                title: 'Desculpe!!!',
                text: 'Não foi possível concluir!!!',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            }
            this.saveData = false;
            this.modal.detalhes = false;
          }
        });
    },
    async saveForm(sendForm) {
      sendForm.notify = [];
      this.notifications.selected.forEach(element => {
        if (element.channel !== null || element.channel !== undefined || element.channel !== "") {
          sendForm.notify.push({ channel: element.channel, type: element.type, timeline: element.timeline })
        }
      });
      let retSavefForm = false;
      await axios
        .put(`${baseApiUrl}/empresas/${this.codigo_empresa}`, sendForm)
        .then((response) => {
          retSavefForm = response.data.retorno;
        })
        .catch((error) => {
          retSavefForm = -1;
        })
      return retSavefForm;
    },
    btnSave() {
      this.saveData = true;
    },
    updateSisfreteNS() {
      this.$swal({
        title: 'SERÁ ALTERADO O ENDEREÇO DO CALCULO DA COTAÇÃO DA LOJA!!!',
        text: 'Continuar com a Alteração ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: 'Ufa!!!',
              text: `Opção bloqueada no momento.`,
              icon: 'warnning',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          }
        });
    },
    async authorizationML() {
      let state = await this.stringRandomica(26);
      state += this.codigo_empresa;
      let url = this.windowNew.url;
      url += `&state=${state}`;
      this.windowNew.result = window.open(url, this.windowNew.name)
    },
    async authorizationNS(sendUpdate) {
      if ( sendUpdate.urlNuvemShop != "" ) {
        sendUpdate.urlNuvemShop = sendUpdate.urlNuvemShop.replace('https://', '')
        sendUpdate.urlNuvemShop = sendUpdate.urlNuvemShop.replace('/', '')
        this.saveForm(sendUpdate);

        let url = `http://${sendUpdate.urlNuvemShop}/admin/apps/1356/authorize/`;
        this.windowNew.result = window.open(url, 'AppSisfreteNuvemShop')

        this.saveData = false;
        this.modal.detalhes = false;
      }
    },
    async stringRandomica(size = 16) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let token = '';
      for (let i = 0; i < size; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters.charAt(randomIndex);
      }
      return token;
    },
    async generateTokenBlingV3() {
      let state = await this.stringRandomica(26);
      state += this.codigo_empresa;
      let url = `${baseApiUrl}/AppSisfreteBlingV3`;
      url += `?state=${state}`;
      this.windowNew.result = window.open(url, 'AppSisfreteBlingV3');

      this.saveData = false;
      this.modal.detalhes = false;
    },
    async generateToken() {
      this.detalhes.rastreioTokenSisfrete = await this.stringRandomica();
      await this.saveToken();
      this.btnSave();
    },
    async generateApiKey() {
      this.detalhes.apikeyIntLog = await this.stringRandomica();
      await this.saveForm(this.detalhes);
      this.modal.detalhes = false;
    },
    confirmDeleteToken() {
      this.$swal({
        title: 'Alerta',
        html: `Deseja realmente excluir o Token Sisfrete ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Concluir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          this.detalhes.rastreioTokenSisfrete = '';
          this.saveToken();
        }
      });
    },
    confirmDeleteTokenBling() {
      this.$swal({
        title: 'Alerta',
        html: `Deseja realmente excluir o Token Bling?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Concluir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          this.detalhes.tokenBlingV3 = '';
          this.saveToken();
        }
      });
    }
  },
  computed: {
    getTitleStatus() {
      return `Os Pedidos serão baixados conforme Status Selecionados: ( ${this.detalhes.appTray_status_pedidos.length} )`;
    },
    getTitleStatusWake() {
      return `Os Pedidos serão baixados conforme Status Selecionados: ( ${this.detalhes.appstatuspedidosWake.length} )`;
    }
  },
  watch: {
    'rastreioTokenSisfrete': function (val, oldVal) {
      if (!val && this.detalhes.rastreioTokenSisfrete.length > 0) {
        this.$swal({
          title: 'Alerta',
          html: `Desabilitar Sisfrete Token irá excluir o Token. <br>Deseja prosseguir ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Concluir',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#4BB543',
          cancelButtonColor: '#d33',
          allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.detalhes.rastreioTokenSisfrete = "";
            this.saveToken();
          } else {
            this.rastreioTokenSisfrete = true;
          }
        });
      }
    },
    'detalhes.pedidosFastCommerce': function (val, oldVal) {
      if (!val && Number(this.detalhes.FastCommerce.StoreId) > 0) {
        this.$swal({
          title: 'Alerta',
          html: `Desabilitar FastCommerce?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Concluir',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#4BB543',
          cancelButtonColor: '#d33',
          allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
          } else {
            this.detalhes.pedidosFastCommerce = true;
          }
        });
      }
    },

  },
};
</script>

<style scoped>
.btnSalvar {
  color: #fff !important;
}
</style>